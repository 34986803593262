import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../business-domain/User';
import { TokenService } from './token.service';
import { Token } from './token.interface';

// DAI: LoginResponse should be adapted from BackEnd to match Token in FrontEnd
export interface LoginResponse {
  token: string;
  tokenExpiry: number;
}

export class ErrorType {
  static USER_NOT_FOUND = new ErrorType('Invalid username');
  static WRONG_PASSWORD = new ErrorType('Invalid password');
  static USER_NOT_CONFIRMED = new ErrorType('User not confirmed');
  static NO_CONNECTION = new ErrorType('No Connection to server');
  static DUPLICATE_USER = new ErrorType('User exists already');
  constructor(readonly message: string) {}
}

@Injectable()
export class AuthService {
  isInAiLicenceGroup: boolean;
  isInPorterLicenceGroup: boolean;
  isInStreamingLicenceGroup: boolean;
  isInManufacturerLicenceGroup: boolean;
  isInFingerhausAdminGroup: boolean;
  isInFingerhausConsultantGroup: boolean;
  isInFingerhausProductManagementGroup: boolean;
  isInFingerhausBuilderGroup: boolean;
  isInAiAlphaGroup: boolean;
  isInReadOnlyGroup: boolean;

  constructor(private http: HttpClient, private tokenService: TokenService) {
    console.log('AuthService constructor');

    this.isInAiLicenceGroup = false;
    this.isInPorterLicenceGroup = false;
    this.isInStreamingLicenceGroup = false;
    this.isInManufacturerLicenceGroup = false;
    this.isInFingerhausAdminGroup = false;
    this.isInFingerhausConsultantGroup = false;
    this.isInFingerhausProductManagementGroup = false;
    this.isInFingerhausBuilderGroup = false;
    this.isInAiAlphaGroup = false;
    this.isInReadOnlyGroup = false;
  }

  isAuthenticated(): boolean {
    return this.tokenService.hasValidToken();
  }

  register({
    company,
    username,
    email,
    password,
    industry,
    city,
    zipcode,
    street,
    houseNumber,
    firstName,
    lastName,
  }): Observable<any> {
    return this.http
      .post(environment.apiUrl + environment.usersEndpoint, {
        company,
        username,
        email,
        password,
        industry,
        city,
        zipcode,
        street,
        houseNumber,
        firstName,
        lastName,
      })
      .pipe(
        catchError((error) => {
          if (error.error.message === 'DUPLICATE_USER') throw ErrorType.DUPLICATE_USER;
          throw ErrorType.NO_CONNECTION;
        })
      );
  }

  logout() {
    this.tokenService.deleteToken();
  }

  login(email: string, password: string): Observable<any> {
    this.logout(); // just to be sure

    return this.http
      .post<LoginResponse>(environment.apiUrl + environment.sessionsEndpoint, { email, password })
      .pipe(
        map(
          (result) => {
            const token: Token = {
              value: result.token,
              expiry: String(result.tokenExpiry),
            };

            this.tokenService.setToken(token);
          },
          catchError((error) => {
            if (error === ErrorType.USER_NOT_FOUND || error.error.message === 'USER_NOT_FOUND')
              throw ErrorType.USER_NOT_FOUND;
            else if (error === ErrorType.WRONG_PASSWORD || error.error.message === 'WRONG_PASSWORD')
              throw ErrorType.WRONG_PASSWORD;
            else if (
              error === ErrorType.USER_NOT_CONFIRMED ||
              error.error.message === 'USER_NOT_CONFIRMED'
            )
              throw ErrorType.USER_NOT_CONFIRMED;
            else throw ErrorType.NO_CONNECTION;
          })
        )
      );
  }

  /** returns true if the code is a valid */
  isValidCode(code: string): Observable<boolean> {
    return this.http
      .post<LoginResponse>(environment.apiUrl + '/sessions/validate', { code })
      .pipe(map((result) => !!result.token));
  }

  requestPasswordReset(email: string): Observable<any> {
    return this.http.post(environment.apiUrl + environment.usersEndpoint + '/requestReset', {
      email,
    });
  }

  getEmailForResetToken(token: string): Observable<{ email: string }> {
    return this.http.get<{ email: string }>(
      environment.apiUrl + environment.usersEndpoint + `/reset/${token}`
    );
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.post(environment.apiUrl + environment.usersEndpoint + '/reset', {
      token,
      password,
    });
  }

  confirm(token: string): Observable<any> {
    return this.http.post(environment.apiUrl + environment.usersEndpoint + '/confirmation', {
      token,
    });
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(environment.apiUrl + environment.usersEndpoint);
  }

  hasPlatformAccess(): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map((user) => {
        console.log('hasPlatformAccess user.groups: ', user.groups);
        for (let user_group of environment.PORTER_LICENCE_GROUPS) {
          if (user.groups.includes(user_group) || user.admin) {
            return true;
          }
        }
        return false;
      })
    );
  }
  
  hasFeatureFlag(user: User, featureFlag: string): boolean {
    return user.featureFlags.length > 0 && user.featureFlags?.includes(featureFlag);
  }

  hasUserGroup(user: User, userGroups: string[]): boolean {
    return userGroups.filter((v) => user.groups.includes(v)).length > 0;
  };
  
  setUserGroups(user: User) {
    /*
    Reconsider future usage of user permissions as planned in FH-204; See same comment in other components
    this.setUserPermissions(user);
    */

    this.isInAiLicenceGroup = this.hasUserGroup(user, environment.AI_LICENCE_GROUPS);
    this.isInPorterLicenceGroup = this.hasUserGroup(user, environment.PORTER_LICENCE_GROUPS);
    this.isInStreamingLicenceGroup = this.hasUserGroup(
      user,
      environment.STREAMING_LICENCE_GROUP
    );
    this.isInManufacturerLicenceGroup = this.hasUserGroup(
      user,
      environment.PORTER_MANUFACTURER_GROUPS
    );
    this.isInFingerhausAdminGroup = this.hasUserGroup(
      user,
      environment.FINGERHAUS_ADMIN_GROUP
    );
    this.isInFingerhausConsultantGroup = this.hasUserGroup(
      user,
      environment.FINGERHAUS_CONSULTANT_GROUP
    );
    this.isInFingerhausProductManagementGroup = this.hasUserGroup(
      user,
      environment.FINGERHAUS_PRODUCT_MANAGEMENT_GROUP
    );
    this.isInFingerhausBuilderGroup = this.hasUserGroup(user, environment.FINGERHAUS_BUILDER);
    this.isInAiAlphaGroup = this.hasUserGroup(user, environment.AI_ALPHA_GROUP);
    this.isInReadOnlyGroup = this.hasUserGroup(user, environment.READ_ONLY_GROUP);
  }

  /*
  Reconsider future usage of user permissions as planned in FH-204; See same comment in other components

   isGrantedPermission(resource, askedPermission): boolean {
    this.permissions.length;
    for (let i = 0; i < this.permissions.length; i++) {
      console.log('IN LOOP');
      if (resource == this.permissions[i].resource) {
        for (const permType of this.permissions[i].permissionTypes) {
          console.log('IN INNER LOOP');
          if (askedPermission == permType) {
            return true;
          }
        }
      }
      return false;
    }
  }

  setUserPermissions(user) {
    //for simulation:
    user['permissions'] = [
      {
        resource: 'ARTICLE',
        permissionTypes: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
      },
      {
        resource: 'USER',
        permissionTypes: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
      },
    ];

    this.permissions = user.permissions;
  } */
}
