import { Inject, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../../../services/auth.service';
import { VersionService } from '../../../services/version.service';
import { Project } from '../../../business-domain/Project';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { StreamingService } from '../../../services/streaming.service';
import { User } from '../../../business-domain/User';
import { ExporterPluginService } from '../../../services/exporter-plugin.service';
import { StreamDialogComponent } from '../../../components/dialogs/stream-dialog/stream-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EnvironmentService } from '../../../services/environment.service';
import { BaseComponent } from '../../../modules/asset-catalog/components/base-component/base-component.component';
import { UrlService } from '../../../services/url.service';

const DOWNLOAD_ICON_NAME = 'download';
const PORTER_ICON_NAME = 'porter-logo';
const PORTER_WORDMARK_ICON_NAME = 'porter-wortmarke';
const FINGERHAUS_LOGO_ICON_NAME = 'fingerhaus-logo';

const DOWNLOAD_ICON_URL = 'assets/icons/download-icon.svg';
const PORTER_ICON_URL = 'assets/icons/porter-logo.svg';
const PORTER_WORDMARK_URL = 'assets/icons/porter-wortmarke.svg';
const FINGERHAUS_LOGO_URL = 'assets/icons/fingerhaus-logo.svg';

const FLOOR_PLAN_EDITOR_PATH = 'grundriss-editor';
const MANUFACTURER_PATH = 'hersteller';
const CONFIGURATION_PATH = 'bemusterung';

const FLOOR_PLAN_URL = '/grundrisse';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopMenuComponent extends BaseComponent implements OnInit {
  @Input()
  projectName: string;
  @Input()
  newProjects: Project[];

  user: User;
  companyName: string;
  email: string;
  hideProfileMenu = true;
  hasStreamAccess: boolean;
  isAdmin: Boolean;
  routerFloorPlanLink = FLOOR_PLAN_URL;
  usesExportPlugin = this.exportPluginService.usesExport();

  constructor(
    protected environmentService: EnvironmentService,
    public authService: AuthService,
    private versionService: VersionService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private streamingService: StreamingService,
    private exportPluginService: ExporterPluginService,
    private dialog: MatDialog,
    private urlService: UrlService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(environmentService);
    this.registerIcons();
  }

  async ngOnInit() {
    if (this.authenticated) {
      const user = await this.authService.getCurrentUser().toPromise();
      this.user = user;
      this.companyName = user.company;
      this.email = user.email;
      this.isAdmin = user.admin;
      this.authService.setUserGroups(user);

      this.hasStreamAccess = await this.streamingService.hasStreamingAccess().toPromise();
      this.hideProfileMenu = this.isEmbeddedAsIframe();
    }
  }

  get authenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  logout() {
    this.authService.logout();
  }

  getLatestVersion() {
    return this.versionService.getLatestVersion().then(
      (res) => {
        this.document.location.href = res.path;
      },
      (error) => {
        console.log('Failed to navigate to link, with error: ' + error);
      }
    );
  }

  streamButtonClicked() {
    this.streamingService.hasStreamingAccess().subscribe((hasAccess) => {
      if (!hasAccess) return;

      this.dialog.open(StreamDialogComponent, StreamDialogComponent.options());
    });
  }

  shouldShowHelpAndSupportButton(): Boolean {
    return (
      this.authService.isInAiLicenceGroup ||
      this.authService.isInPorterLicenceGroup ||
      this.authService.isInManufacturerLicenceGroup ||
      this.isAdmin
    );
  }

  isProductManagementAccessible(): boolean {
    return (
      this.authService.isInFingerhausAdminGroup ||
      this.authService.isInFingerhausProductManagementGroup ||
      this.authService.isInFingerhausConsultantGroup
    );
  }

  isProductCatalogAccessible(): boolean {
    return (
      !this.authService.isInFingerhausProductManagementGroup &&
      !this.authService.isInFingerhausConsultantGroup
    );
  }

  isBuildingProjectsAccessible(): boolean {
    return false; // We temporary hide the Button until it can actually be used
    // return (this.authService.isInFingerhausAdminGroup || this.authService.isInFingerhausConsultantGroup);
  }

  isInFloorplanEditor(): boolean {
    return this.urlService.urlContains(FLOOR_PLAN_EDITOR_PATH);
  }

  isInManufacturer(): boolean {
    return this.urlService.urlContains(MANUFACTURER_PATH);
  }

  isInConfiguration(): boolean {
    return this.urlService.urlContains(CONFIGURATION_PATH);
  }

  private registerIcons() {
    this.iconRegistry.addSvgIcon(
      DOWNLOAD_ICON_NAME,
      this.sanitizer.bypassSecurityTrustResourceUrl(DOWNLOAD_ICON_URL)
    );
    this.iconRegistry.addSvgIcon(
      PORTER_ICON_NAME,
      this.sanitizer.bypassSecurityTrustResourceUrl(PORTER_ICON_URL)
    );
    this.iconRegistry.addSvgIcon(
      PORTER_WORDMARK_ICON_NAME,
      this.sanitizer.bypassSecurityTrustResourceUrl(PORTER_WORDMARK_URL)
    );
    this.iconRegistry.addSvgIcon(
      FINGERHAUS_LOGO_ICON_NAME,
      this.sanitizer.bypassSecurityTrustResourceUrl(FINGERHAUS_LOGO_URL)
    );
  }
}