export const environment= {
  production: true,
  apiUrl: 'https://app.porter.de/api',
  envSystem: 'Production',
  customerSystem: '',

  usersEndpoint: '/users',
  sessionsEndpoint: '/sessions',
  sessionRefreshEndpoint: '/sessions/refresh',
  projectEndpoint: '/projects/:projectId',
  projectsEndpoint: '/projects',
  adminGroup: 'admin',
  streamGroup: 'pixelstreaming',
  AI_LICENCE_GROUPS: [
    'ai-entry',
    'ai-basic',
    'ai-professional',
    'ai-professional-plus',
    'ai-enterprise-solution',
  ],
  STREAMING_LICENCE_GROUP: ['pixelstreaming', 'porter-expert'],
  PORTER_LICENCE_GROUPS: ['porter-entry', 'porter-professional', 'porter-expert'],
  PORTER_MANUFACTURER_GROUPS: ['porter-elements'],
  FINGERHAUS_ADMIN_GROUP: ['fingerhaus-admin'],
  FINGERHAUS_CONSULTANT_GROUP: ['fingerhaus-berater'],
  FINGERHAUS_PRODUCT_MANAGEMENT_GROUP: ['fingerhaus-artikelpflege'],
  FINGERHAUS_BUILDER: ['fingerhaus-user'],
  AI_ALPHA_GROUP: ['admin', 'ai-alpha'],
  READ_ONLY_GROUP: ['fhReadOnly'],

  qrGroup: 'porter-qr-scan',

  consumerEndpoint: '/consumer',
};
